<template>
  <div class="mx-auto ilike-btn-container">
    <v-btn rounded block color="success" @click="goLeadCapture" x-large>
      I like it! Continue!
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    goLeadCapture() {
      this.$router.push({ name: 'Lead Capture' });
    }
  }
};
</script>

<style scoped>
.ilike-btn-container {
  max-width: 400px;
  width: -webkit-fill-available;
}
</style>
