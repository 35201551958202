<template>
  <v-btn
    depressed
    rounded
    color="#dddddd"
    class="headerButtonText--text px-6 ml-sm-4 mt-3 mt-sm-0"
    @click="goBack"
  >
    <v-icon color="buttonText" left>mdi-arrow-left</v-icon>
    Back to Data Entry
  </v-btn>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push({ name: 'Form' });
    }
  }
};
</script>
