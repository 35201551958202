<template>
  <div class="my-8">
    <v-card
      class="mx-auto mb-8 py-6 px-4 px-sm-10 d-flex flex-column justify-start align-center"
      elevation="3"
    >
      <v-sheet width="80%" min-width="300" max-width="1000"
        ><CustomScenarioChart :period="customChartPeriod" category="age" /></v-sheet
    ></v-card>

    <v-card
      class="mx-auto mb-8 py-4 px-2 px-sm-4 d-flex flex-column justify-space-between align-center align-md-start"
      elevation="3"
    >
      <ConsiderVoluntaryPayments />
      <ScheduleRMPayments />
      <InterestRateScenarioAndPVARate />
      <ImportantData />
      <!-- <AuditButton v-if="!isResultSaved" /> -->
    </v-card>
  </div>
</template>

<script>
import CustomScenarioChart from '../Charts/CustomScenarioChart.vue';
import ConsiderVoluntaryPayments from './ConsiderVoluntaryPayments.vue';
import ScheduleRMPayments from './ScheduleRMPayments.vue';
import InterestRateScenarioAndPVARate from './InterestRateScenarioAndPVARate.vue';
import ImportantData from './ImportantData/ImportantData.vue';
// import AuditButton from '../AuditButton.vue';

import helpers from '../../helpers';

export default {
  components: {
    CustomScenarioChart,
    ConsiderVoluntaryPayments,
    ScheduleRMPayments,
    InterestRateScenarioAndPVARate,
    ImportantData
    // AuditButton
  },
  computed: {
    customChartPeriod() {
      return this.maxPeriod(this.youngestOwnerAge);
    }
  },
  methods: {
    maxPeriod: helpers.getMaxPeriod
  }
};
</script>

<style scoped></style>
