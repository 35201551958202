<template>
  <div>
    <ScenarioHeader :tabs="tabs" @onTabChange="handleTabChange" />
    <v-tabs-items v-model="tabs" class="mt-8" touchless>
      <v-tab-item>
        <IncreaseFunds />

        <div class="mx-auto next-btn-container" v-if="!isResultSaved">
          <v-btn rounded block color="success" @click="goNext" x-large> Next </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item>
        <StaticExample />

        <div class="mx-auto next-btn-container" v-if="!isResultSaved">
          <v-btn rounded block color="success" @click="goNext" x-large> Next </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item>
        <BuildYourOwnRM />
        <ILikeItButton v-if="!isResultSaved" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ScenarioHeader from '../ScenarioHeader.vue';
import IncreaseFunds from './IncreaseFunds.vue';
import StaticExample from './StaticExample.vue';
import BuildYourOwnRM from './BuildYourOwnRM/index.vue';
import ILikeItButton from '../../components/ILikeItButton.vue';

export default {
  data: () => ({
    tabs: null
  }),
  components: { ScenarioHeader, IncreaseFunds, StaticExample, BuildYourOwnRM, ILikeItButton },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    }
  },
  methods: {
    handleTabChange(index) {
      this.$data.tabs = index;
    },
    goNext() {
      this.$data.tabs = this.$data.tabs + 1;
    }
  }
};
</script>

<style scoped>
.next-btn-container {
  max-width: 400px;
  width: -webkit-fill-available;
}
</style>
