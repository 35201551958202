<template>
  <v-card
    class="mx-auto mb-2 py-2 px-2 px-sm-4 d-flex flex-column justify-space-between align-center align-md-start"
    elevation="3"
  >
    <VoluntaryPaymentsChart />
    <InterestRateScenario />
    <PropertyValueAppreciationRate />
    <DoYouWantImmediateCash />
    <ConsiderVoluntaryPayments />
    <SchedulePayments />
  </v-card>
</template>

<script>
import InterestRateScenario from './InterestRateScenario.vue';
import PropertyValueAppreciationRate from './PropertyValueAppreciationRate.vue';
import DoYouWantImmediateCash from './DoYouWantImmediateCash.vue';
import ConsiderVoluntaryPayments from './ConsiderVoluntaryPayments.vue';
import VoluntaryPaymentsChart from './VoluntaryPaymentsChart.vue';
import SchedulePayments from './SchedulePayments/SchedulePayments.vue';

export default {
  components: {
    InterestRateScenario,
    PropertyValueAppreciationRate,
    DoYouWantImmediateCash,
    ConsiderVoluntaryPayments,
    SchedulePayments,
    VoluntaryPaymentsChart
  }
};
</script>

<style scoped></style>
