<template>
  <div class="elevation-4 bg-white">
    <div class="d-flex flex1 justify-space-between align-center flex-row flex-wrap py-8 px-4">
      <BackToEntryPageButton v-if="!isResultSaved" />

      <div class="d-flex align-center flex-row flex-wrap">
        <div
          class="mx-4 font-weight-medium rounded-pill px-6 py-1 light-blue darken-2 text-h6 white--text"
        >
          {{ loanOfficer.firstName }}
          {{ loanOfficer.lastName }}
        </div>
      </div>
    </div>
    <div v-if="isScenarioA">
      <v-sheet class="d-flex flex-row mx-6">
        <v-tabs color="headerTabs" v-model="tabsProp">
          <v-tab>GROWTH IN BENEFITS</v-tab>
          <v-tab>Static example</v-tab>
          <v-tab>Build your own Reverse Mortgage</v-tab>
        </v-tabs>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import BackToEntryPageButton from './BackToEntryPageButton.vue';

export default {
  props: ['tabs'],
  components: { BackToEntryPageButton },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    tabsProp: {
      get() {
        return this.$props.tabs;
      },
      set(tabs) {
        this.$emit('onTabChange', tabs);
      }
    },
    isScenarioA() {
      return (
        this.$store.state.scenarioData.scenario ===
        this.$store.state.scenarioData.allScenarioTitles.scenarioATitle
      );
    },
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    }
  }
};
</script>

<style scoped>
.bg-white {
  background-color: white;
}
</style>
