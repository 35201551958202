<template>
  <v-sheet
    class="
      d-flex
      flex-md-row flex-column
      align-start
      rounded-lg
      mb-10
      px-1 px-sm-4
      py-4
      generalBackground
    "
    width="100%"
    ><v-sheet
      style="flex: 1"
      class="d-flex flex-column align-center align-sm-start generalBackground mb-6 mb-md-0"
    >
          <v-sheet style="flex: 1" min-width="280" class="generalBackground" width="100%"
      ><CustomScenarioChart :period="customChartPeriod" category="age" />
      </v-sheet
  ></v-sheet
    >
  </v-sheet>
</template>

<script>
import CustomScenarioChart from '../../Charts/CustomScenarioChart.vue';
import helpers from '../../../helpers';

export default {
  components: {
    CustomScenarioChart
  },
  computed: {
    youngestOwnerAge() {
      return this.$store.state.scenarioData.landingPageFormData.basic.youngestOwnerAge;
    },
    customChartPeriod() {
      return this.maxPeriod(this.youngestOwnerAge);
    }
  },
  methods: { maxPeriod: helpers.getMaxPeriod }
};
</script>

<style scoped></style>
